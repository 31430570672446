<template>
    <div>
        <v-alert
            :value="errorMessage"
            type="error"
            class="my-2">
            Please select ads to preview.
        </v-alert>

        <StyledCard>
            <template #heading>
                Campaign/Play Management
            </template>

            <template #action-buttons>
                <action-button
                    class="mr-3"
                    icon="ad"
                    target="_blank"
                    @click="gatherAdIdsForSharing()">
                    Preview Ads
                </action-button>
                <action-button
                    class="mr-3"
                    icon="outbound-link"
                    target="_blank"
                    :href="dealerFacebookAdAccountUrl">
                    Ad Account
                </action-button>
                <action-button
                    icon="refresh"
                    :loading="loadingCampaigns"
                    @click="getCampaigns()">
                    Refresh
                </action-button>
            </template>

            <div
                v-if="loadingCampaigns"
                class="text-xs-center py-5">
                <loader />
            </div>
            <custom-table
                v-else
                id="campaign-tree-table"
                name="campaign-tree"
                min-width="1200px"
                :items="treeData"
                :fields="fields"
                :loading="loadingCampaigns"
                :notification="notification"
                :notification-type="notificationType"
                :rows-per-page="50"
                initial-sorting="Created"
                initial-sort-direction="desc"
                selectable
                expandable
                slim-cells
                @select-item="selectCampaign">
                <template #expand="{ item }">
                    <table class="expandable-table">
                        <template v-for="adset in item.adsets.data">
                            <tr
                                :key="adset.id"
                                class="middle-tr">
                                <td
                                    width="8%"
                                    class="selection-cell">
                                    <button
                                        class="row-toggle"
                                        @click="toggleExpand(adset.id)">
                                        <div
                                            :class="{'rotate': adset.expanded}"
                                            class="toggle">
                                            <icon
                                                size="20"
                                                name="collapse" />
                                        </div>
                                    </button>
                                    <span
                                        class="styled-checkbox-checkmark"
                                        :class="{'selected': adset.selected}"
                                        @click.stop="toggleAdset(adset.id)" />
                                </td>
                                <td width="40%">
                                    <campaign-name
                                        :custom-name="adset.name"
                                        type="adset" />
                                </td>
                                <td width="15%">
                                    <campaign-status :effective-status="adset.effective_status" />
                                </td>
                                <td width="27%">
                                    <campaign-created-date :created-date="adset.created_time" />
                                </td>
                                <td width="10%">
                                    <action-button
                                        icon="outbound-link"
                                        class="mx-3"
                                        @click="open(item.id, adset.id)">
                                        Manage
                                    </action-button>
                                </td>
                            </tr>
                            <template v-if="adset.expanded">
                                <tr
                                    v-for="ad in adset.ads.data"
                                    :key="ad.id"
                                    class="low-tr">
                                    <td
                                        width="8%"
                                        class="selection-cell inner">
                                        <span
                                            class="styled-checkbox-checkmark"
                                            :class="{'selected': ad.selected}"
                                            @click.stop="toggleAd(ad.id)" />
                                    </td>
                                    <td width="40%">
                                        <campaign-name
                                            :custom-name="ad.name"
                                            :image="ad.creative.thumbnail_url"
                                            type="ad" />
                                    </td>
                                    <td width="15%">
                                        <campaign-status
                                            class="ml-3"
                                            :effective-status="ad.effective_status" />
                                    </td>
                                    <td width="27%">
                                        <campaign-created-date
                                            class="ml-3"
                                            :created-date="ad.created_time" />
                                    </td>
                                    <td width="10%">
                                        <action-button
                                            icon="outbound-link"
                                            class="mx-3"
                                            @click="open(item.id, adset.id, ad.id)">
                                            Manage
                                        </action-button>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </table>
                </template>
            </custom-table>
        </StyledCard>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapState, mapGetters } from 'vuex';
import Icon from '@/components/globals/Icon';
import Loader from '@/components/globals/Loader';
import getNumberFormats from '@/helpers/numberFormats';
import StyledCard from '@/components/globals/StyledCard';
import ActionButton from '@/components/globals/ActionButton';
import CampaignName from '@/components/playbooks/CampaignName';
import CampaignStatus from '@/components/playbooks/CampaignStatus';
import CustomTable from '@/components/globals/CustomTable/index.vue';
import CampaignCreatedDate from '@/components/playbooks/CampaignCreatedDate';

    export default {
        name: 'CampaignTree',
        components: {
            Icon,
            Loader,
            StyledCard,
            CustomTable,
            CampaignName,
            ActionButton,
            CampaignStatus,
            CampaignCreatedDate,
        },
        data() {
            return {
                treeData: [],
                shareAdIds: [],
                loadingCampaigns: false,
                numberFormats: getNumberFormats(),
                errorMessage: false,
                notification: '',
                notificationType: '',
                fields: [
                    {
                        id: 'name',
                        header: 'Name',
                        value: 'name',
                        align: 'left',
                        width: '40%',
                        sortable: true,
                        type: 'component',
                        component: () => import('@/components/playbooks/CampaignName'),
                    },
                    {
                        id: 'status',
                        header: 'Status',
                        value: 'effective_status',
                        align: 'left',
                        width: '15%',
                        sortable: true,
                        type: 'component',
                        component: () => import('@/components/playbooks/CampaignStatus'),
                    },
                    {
                        id: 'created',
                        header: 'Created',
                        value: 'created_time',
                        align: 'left',
                        width: '15%',
                        sortable: true,
                        type: 'component',
                        component: () => import('@/components/playbooks/CampaignCreatedDate'),
                    },
                    {
                        id: 'daily_budget',
                        header: 'Current Daily Budget',
                        value: 'daily_budget',
                        align: 'center',
                        width: '13%',
                        sortable: true,
                        type: 'component',
                        component: () => import('@/components/playbooks/CampaignBudget'),
                    },
                    {
                        id: 'manage',
                        header: '',
                        value: '',
                        align: 'left',
                        width: '10%',
                        sortable: true,
                        type: 'component',
                        component: () => import('@/components/playbooks/CampaignManage'),
                        callback: (item) => {
                            this.open(item.id);
                        }
                    }
                ]
            };
        },
        computed: {
            ...mapState({
                currentDealerId: (state) => state.dealer.currentDealerId,
                currentAgency: (state) => state.agency.currentAgency,
                currentUser: (state) => state.user.user,
            }),
            ...mapGetters([
                'dealerFacebookAdAccountUrl',
                'dealerFacebookAdsFilter',
                'dealerFacebookAdAccount'
            ])
        },
        created() {
            this.getCampaigns();
        },
        methods: {
            open(campaignId, adSetId, adId) {
                const url = this.dealerFacebookAdsFilter({
                    dateRange: this.dateRange,
                    campaigns: [ campaignId ],
                    adSets: [ adSetId ],
                    ads: [ adId ]
                });
                window.open(url);
            },
            async generateAdsPreviewUrl() {
                if (this.shareAdIds.length == 0) {
                    this.errorMessage = true;
                    return;
                } else {
                    this.errorMessage = false;
                }

                let uuid = uuidv4();

                let data = {
                    'key': 'ad_previews:' + uuid,
                    'agency_id': this.currentAgency.id,
                    'user_id': this.currentUser.id,
                    'public': true,
                    'data': {
                        ad_ids: this.shareAdIds,
                        ad_account: this.dealerFacebookAdAccount,
                        agency_id: this.currentAgency.id
                    }
                };

                //TODO: try catch
                //TODO: loading state
                await this.$http.post('/data-storage', data);

                let route = this.$router.resolve({ name: 'ad-previews', query: {
                        id: uuid,
                    }
                });

                window.open(route.href, '_blank');
            },
            hydrateFacebookData(data) {
                //Make sure this is not null
                data.forEach(campaign => {
                    campaign.show = false;
                    campaign.share = false;
                    if (campaign.adsets) {
                        campaign.adsets.data.forEach(adset => {
                            adset.show = false;
                            adset.share = false;
                            if (adset.ads) {
                                adset.ads.data.forEach(ad => {
                                    ad.show = false;
                                    ad.share = false;
                                });
                            }
                        });
                    }
                });
                data.forEach(campaign => {
                    campaign.selected = false;
                    const hasAdsets = campaign.adsets?.data ?? false;
                    if (!hasAdsets) {
                        campaign.adsets = {
                            data: []
                        };
                    }
                    campaign.adsets.data.forEach(adset => {
                        adset.selected = false;
                        adset.expanded = false;
                        const hasAds = adset.ads?.data ?? false;
                        if (!hasAds) {
                            adset.ads = {
                                data: []
                            };
                        }
                        adset.ads.data.forEach(ad => {
                            ad.selected = false;
                        });
                    });
                });
                this.treeData = data;
            },
            gatherAdIdsForSharing() {
                this.shareAdIds = [];
                this.treeData.forEach(campaign => {
                    if (campaign.adsets) {
                        campaign.adsets.data.forEach(adset => {
                            if (adset.ads) {
                                adset.ads.data.forEach(ad => {
                                    if (ad.selected) {
                                        this.shareAdIds.push(ad.id);
                                    }
                                });
                            }
                        });
                    }
                });
                this.generateAdsPreviewUrl();
            },
            selectCampaign(item) {
                this.treeData.forEach(campaign => {
                    if (campaign.id === item.id) {
                        campaign.selected = campaign.selected === false;
                        if (campaign.selected === false) {
                            campaign.selected = false;
                            if (campaign.adsets) {
                                campaign.adsets.data.forEach(adset => {
                                    adset.selected = false;
                                    if (adset.ads) {
                                        adset.ads.data.forEach(ad => {
                                            ad.selected = false;
                                        });
                                    }
                                });
                            }
                        } else {
                            campaign.selected = true;
                            if (campaign.adsets) {
                                campaign.adsets.data.forEach(adset => {
                                    adset.selected = true;
                                    if (adset.ads) {
                                        adset.ads.data.forEach(ad => {
                                            ad.selected = true;
                                        });
                                    }
                                });
                            }
                        }
                    }
                });
            },
            toggleAdset(adsetId) {
                this.treeData.forEach(campaign => {
                    if (campaign.adsets) {
                        campaign.adsets.data.forEach(adset => {
                            if (adset.id === adsetId) {
                                adset.selected = adset.selected === false;
                                if (adset.selected === false) {
                                    adset.selected = false;
                                    if (adset.ads) {
                                        adset.ads.data.forEach(ad => {
                                            ad.selected = false;
                                        });
                                    }
                                } else {
                                    adset.selected = true;
                                    if (adset.ads) {
                                        adset.ads.data.forEach(ad => {
                                            ad.selected = true;
                                        });
                                    }
                                }
                            }
                        });
                    }
                });
            },
            toggleAd(adId) {
                this.treeData.forEach(campaign => {
                    campaign.adsets.data.forEach(adset => {
                        adset.ads.data.forEach(ad => {
                            if (ad.id === adId) {
                                ad.selected = ad.selected === false;
                            }
                        });
                    });
                });
            },
            toggleExpand(adsetId) {
                this.treeData.forEach(campaign => {
                    if (campaign.adsets) {
                        campaign.adsets.data.forEach(adset => {
                            if (adset.id === adsetId) {
                                adset.expanded = adset.expanded === false;
                            }
                        });
                    }
                });
            },
            async getCampaigns() {
                this.loadingCampaigns = true;
                this.notification = '';
                this.notificationType = '';
                try {
                    const url = `/dealers/${this.currentDealerId}/graph-api/ad-account/campaigns?fields=name,daily_budget,effective_status,adlabels,created_time,adsets{id,name,created_time,daily_budget,effective_status,ads{id,name,created_time,daily_budget,effective_status,creative.thumbnail_height(32).thumbnail_width(32){id,thumbnail_url}}}`;
                    const response = await this.$http.get(url);
                    this.hydrateFacebookData(response.data);
                    this.loadingCampaigns = false;
                } catch (error) {
                    console.log('Error loading dealer campaigns', error);
                    this.notification = 'Error loading dealer campaigns';
                    this.notificationType = 'error';
                } finally {
                    this.loadingCampaigns = false;
                }
            },
        }
    };
</script>

<style>
#campaign-tree-table .table-row.alt-color {
    background: white;
}
</style>

<style lang="scss" scoped>
.expandable-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 13px;
    tr {
        border-bottom: 1px solid #C7CED3;
        &:last-of-type {
            border-bottom: none;
        }
    }
    td {
        padding: 10px 15px;
    }
    .selection-cell {
        padding: 10px 5px;
        padding-left: 30px;
        &.inner {
            padding-left: 75px;
            & + td {
                padding-left: 30px;
            }
        }
    }
    .middle-tr {
        background: #F5F5F5;
    }
    .low-tr {
        background: #E2E7E9;
    }
}
.styled-checkbox-checkmark {
    position: relative;
    display: inline-block;
    margin-top: 5px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid;
    border-color: $gray;
    background-color: $white;
    transform-origin: center center;
    transform: scale(var(--checkmark-scale));
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    &::after {
        content: '';
        position: absolute;
        display: none;
        left: 5px;
        top: 1px;
        width: 6px;
        height: 11px;
        border: solid $white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
    &:hover {
        background-color: $alabaster-dark;
    }
    &.selected {
        background-color: $blue-btn;
        border-color: $blue-btn;
        &::after {
            display: block;
        }
    }
}
.row-toggle {
    margin-right: 15px;
    transform: translateY(-4px) rotate(-180deg);
    &.rotate {
        transform: translateY(-4px);
    }
}
</style>