<template>
    <div class="campaign-name">
        <template v-if="type === 'campaign'">
            <icon
                class="mr-2"
                name="folder"
                height="16" />
            {{ item.name }}
        </template>
        <template v-if="type === 'adset'">
            <icon
                class="mr-2"
                name="adset"
                height="16" />
            {{ customName }}
        </template>
        <template v-if="type === 'ad'">
            <img
                class="mr-2"
                :src="image"
                alt="">
            {{ customName }}
        </template>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';

export default {
    components: {
        Icon
    },
    props: {
        item: {
            type: Object,
            default: () => {}
        },
        type: {
            type: String,
            default: 'campaign'
        },
        customName: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: ''
        }
    }
};
</script>

<style lang="scss" scoped>
.campaign-name {
    display: flex;
    align-items: center;
    img {
        max-width: 30px;
        height: auto;
        vertical-align: top;
        border: 1px solid #C7CED3;
    }
}
</style>
