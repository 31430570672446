<template>
    <div>
        {{ date }}
    </div>
</template>

<script>
import { format } from 'date-fns';

export default {
    props: {
        item: {
            type: Object,
            default: () => {}
        },
        createdDate: {
            type: String,
            default: ''
        }
    },
    computed: {
        date() {
            if (this.createdDate) {
                return format(window.convertFromUtcToLocalTime(this.createdDate), 'MM/DD/YY @ h:mma');
            } else {
                return format(window.convertFromUtcToLocalTime(this.item.created_time), 'MM/DD/YY @ h:mma');
            }
        }
    }
};
</script>